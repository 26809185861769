import React, {useState} from "react";
import Slider from "react-slick";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import CardItem from '/components/shared/card/CardItem';
import { CarouselArrowLeft, CarouselArrowRight } from '../SvgIcons';

export default function CarouselLandingPage({ productions }) {
const [sliderRef, setSliderRef] = useState(null);

const settings = {
        arrows: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        speed: 500,
        responsive: [
            {
                breakpoint: 870,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 560,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 320,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ]
    };


  return (
      <div className='ml-4'>
          <div className='w-[50vw] flex ml-4 sm:ml-20 md:ml-20 md:justify-center pb-8'>
              <button onClick={sliderRef?.slickPrev} className='m-2'>
                  <div
                      className='rounded-full flex justify-center items-center w-8 h-8 bg-gold opacity-40'>
                      {CarouselArrowLeft()}
                  </div>
              </button>
              <button onClick={sliderRef?.slickNext} className='m-2'>
                  <div className='rounded-full flex justify-center items-center w-8 h-8 bg-gold'>
                      {CarouselArrowRight()}
                  </div>
              </button>
          </div>
          <div className="w-full">
              <div className='w-96 md:w-[52rem] md:h-96'>
                  <Slider ref={setSliderRef} {...settings}>
                      {
                          productions?.map(event => {
                              return (
                                  <CardItem
                                      key={event.slug}
                                      event={event}
                                  />
                              );
                          })
                      }
                  </Slider>
              </div>
          </div>
      </div>
  );
}
