export default function Offers() {
    return (
        <div>
            <div className="w-full bg-[#F5F2ED] mt-16">
                <h1 className="text-gray-dark w-fit h-12 text-lg xl:text-2xl pt-24 ml-[20%] ">
                    Angebote für Gruppen <br/> und Schulklassen
                </h1>
                <div className='text-gray-dark text-base p-8 xl:pl-0 xl:ml-[30%] mt-44 h-fit'>
                    <p>
                        <h1>Herzlich willkommen in unserem Digitalen Theater!</h1>
                        Genau wie in unserem Theater in
                        Bonn spielen wir auch digital fast jeden Vormittag für
                        Schulklassen und Gruppen. Unser Spielplan bietet viele verschiedene Stücke für Kinder und
                        Jugendliche in
                        jedem Alter, die sich hervorragend auch für den gemeinsamen Besuch mit einer Klasse und für
                        den
                        Einsatz im Fachunterricht eignen.

                        <br/><br/>

                        <h1>Didaktisches Begleitmaterial</h1>
                        Zu vielen unserer Stücke haben wir ein theater- und/oder medienpädagogisches Materialheft
                        herausgegeben, das Lehrer*innen viele Anregungen zur Vor- und Nachbereitung des (digitalen)
                        Theaterbesuchs liefert und die vertiefende Behandlung der Themen des jeweiligen Stücks im
                        Schulunterricht fördert. Insbesondere die beiden digital produzierten Stücke ’TKKG - Gefangen in
                        de Vergangenheit’
                        und ‚Die Surfguards - Nur das eine Leben‘ sind auch für den Einsatz im Rahmen der
                        Medienpädagogik konzipiert und geeignet.
                        Die Begleitmaterialien finden Sie als PDF zum Download auf der Seite des jeweiligen Stücks

                        <br/><br/>

                        <h1>Digitales Nachgespräch mit dem Ensemble</h1>
                        Auch bei unseren Online-Vorstellungen bieten wir Gruppen ein moderiertes Nachgespräch mit
                        den Schauspielerinnen und ggf. dem oder der Regisseurin an, wenn es terminlich möglich ist. Das
                        Nachgespräch kann unmittelbar nach der Onlinevorstellung oder auch an einem der folgenden
                        Tage verabredet werden.

                        <br/><br/>
                        
                        <h1>Unter <a className="underline" href="/faq">Hilfe und FAQ</a> finden Sie alle technischen
                            Hinweise</h1>
                        <br/><br/>
                    </p>
                </div>
            </div>
            <div
                className='bg-gray-dark h-8 flex items-center justify-center text-white text-base uppercase'>
                <a href="/impressum" className="mx-2 md:mr-12">Impressum</a>
                <a href="/datenschutz" className="mx-2 md:mr-12">Datenschutz</a>
                <a href="https://www.jt-bonn.de/unternehmen/allgemeine-geschaeftsbedingungen/" className="mx-2 md:mr-12">AGB</a>
                <a href="/faq">FAQ</a>
            </div>
        </div>
    )
        ;
}
