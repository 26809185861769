import NavBar from "/components/shared/navigation/NavBar";
import {motion} from "framer-motion";
import {SvgTailDown3, SvgTheaterCurtains1} from "/components/shared/SvgIcons";
import {Link} from "react-scroll";
import {Swiper, SwiperSlide} from "swiper/react";
import {TicketCardWrapper} from "/helpers/TicketCardWrapper";
import SwiperCore, {Pagination} from 'swiper';
import RoomButton from "/components/shared/buttons/RoomButton";
import GoldButton from "/components/shared/buttons/GoldButton";
import {useRouter} from "next/router";
import AppContext from "/context/AppContext";
import {useContext} from "react";
import {flattenProductionsToShows} from "/utils";

SwiperCore.use([Pagination]);

export default function EntryPage() {
    const router = useRouter();
    const {productions} = useContext(AppContext);
    const shows = flattenProductionsToShows(productions);
    const sortedShows = shows.sort(function (previous, next) {
        return (
            new Date(previous.start_date) -
            new Date(next.start_date)
        );
    });

    if (!shows.length) {
        return <div>Lade Termine...</div>;
    }

    return (
        <div className="bg-cover bg-bottom bg-landingpage w-fit h-fit md:h-[834px] 2xl:h-[1080px] relative">
            <NavBar/>
            <div className="flex ">
                <div className="mt-8 ml-24">
                    <motion.div
                        initial={{opacity: 0, scale: 0.5}}
                        animate={{opacity: 1, scale: 1}}
                        exit={{opacity: 0, scale: 0.5}}
                    >
                    </motion.div>
                </div>
                <div className="flex flex-col ml-48 mt-20 hidden 2xl:block ">
                    <h1 className="w-[809px] h-[260px] text-white pt-[170px] text-3xl headline-shadow">
                        Das Junge Theater Bonn online erleben.
                    </h1>
                    <GoldButton onClick={() => router.push('/foyer')} style='px-8 pt-3 pb-1 text-lg rounded-full mt-32'>
                        EINTRETEN &amp; ENTDECKEN
                    </GoldButton>
                </div>
            </div>
            <div className='hidden 2xl:block mt-4 absolute bottom-0 left-1/2 -translate-x-1/2'>
                <Link
                    className="w-fit flex flex-col items-center cursor-pointer"
                    to="information"
                    smooth={true}
                    duration={1000}
                >
                    <h4 className="text-base tracking-wide text-white uppercase">
                        Wie funktioniert das Online theater?
                    </h4>
                    {SvgTailDown3()}
                </Link>
            </div>
            <div className='md:flex md:mt-24 justify-evenly'>
                <div className="pb-4 md:pb-0 ml-10 md:ml-8 xl:ml-24 md:order-last 2xl:hidden">
                    <h1 className="text-white mt-24 md:mt-8 text-xl sm:text-2xl headline-shadow">
                        Das Junge <br/> Theater Bonn <br/> online erleben.
                    </h1>
                    <GoldButton style='px-4 py-2 text-md rounded-[52px] mt-10'>
                        EINTRETEN &amp; ENTDECKEN
                    </GoldButton>
                </div>
            </div>
        </div>
    );
}
