import React, {useState} from 'react';
import ViewButton from "./buttons/ViewButton";
import {useRouter} from "next/router";
import {Duration} from "luxon";
import {Alarm} from "./SvgIcons";
import {formatRelativeCalendar, formatTime} from '/utils';

const fullUnits = ['years', 'months', 'days', 'hours', 'minutes', 'seconds', 'milliseconds'];
Duration.prototype.toFull = function () {
    return this.shiftTo.apply(this, fullUnits);
};

export default function TicketCard({
                                       src,
                                       event,
                                       next_event,
                                       time_of_next_event,
                                       event_title,
                                       start_date,
                                       hideTicketsButton
                                   }) {
    const router = useRouter();
    const [styleNextEvent, setStyleNextEvent] = useState('');
    const [styleNextEventTime, setStyleNextEventTime] = useState('justify-between');
    const height = 150;

    React.useEffect(() => {
        function handleResize() {
            let element = document.querySelector('#next-event');

            if (element?.getBoundingClientRect().width < 120 || element?.getBoundingClientRect().height > 12) {
                setStyleNextEvent('hidden');
                setStyleNextEventTime('py-2 pr-2 justify-end')
            } else {
                setStyleNextEvent('block justify-between');
            }
        }

        window.addEventListener('resize', handleResize)
    })


    return (
        <div
            className={`flex shadow-bx-dark bg-white w-full h-32 sm:h-36 xl:h-40 ${router.pathname.includes('/ticket-validation') || router.pathname.includes('/program') ? 'rounded-t-[16px]' : 'rounded-[16px]'} xl:pr-2`}>
            {src ? (
                <img
                    className={`float-left ${router.pathname.includes('/ticket-validation') || router.pathname.includes('/program') ? 'rounded-tl-[16px]' : 'rounded-l-[16px]'}`}
                    src={src}
                    width={0.8 * height}
                    height={height}
                />
            ) : null}
            <div
                className='pl-3 flex-1'>
                <div className="flex flex-col mb-2">
                    {next_event ? (
                        <div className={`${styleNextEventTime} flex`}>
                            <p
                                id={'next-event'}
                                className={`${styleNextEvent} mr-2 text-gray-dark w-max text-sm my-4 opacity-50 truncate`}>
                                {next_event}
                            </p>
                            <div className="flex justify-center items-center mx-2">
                                <h4
                                    className={`uppercase w-max text-gold mx-1 text-sm 2xl:text-base`}>
                                    {time_of_next_event}
                                </h4>
                                <div className='mb-2'>
                                    {Alarm()}
                                </div>
                            </div>
                        </div>
                    ) : <div className='flex justify-between'>&nbsp;</div>}

                    <h1
                        className="text-gray-dark capitalize pr-2 text-md w-[40vw] sm:w-[50vw] md:w-[30vw] xl:w-[20vw] 2xl:w-[15vw] truncate my-1 xl:text-lg"
                    >
                        {event_title}
                    </h1>
                    <div className="flex justify-between text-gray-dark">
                        {!hideTicketsButton && (
                            <div className='flex justify-center items-center mb-1 pr-2'>
                                <ViewButton href={`/theater-details/${event?.slug}#tickets`}
                                            className='px-4 pt-1 h-8 mr-2'>
                                    Tickets
                                </ViewButton>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
